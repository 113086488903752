body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f7f7;
}

.ui.small.modal.transition.visible.active.no-action > .actions {
  display: none;
}

.ui.small.modal.transition.visible.active.no-action > .content {
  border-bottom-left-radius: 0.28571429rem;
  border-bottom-right-radius: 0.28571429rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.size1 {
  font-size: 1rem;
}

.mb0 {
  margin-bottom: 0;
}

.mb1 {
  margin-bottom: 1rem;
}

.mb2 {
  margin-bottom: 2rem;
}

.mb3 {
  margin-bottom: 3rem;
}

.mb4 {
  margin-bottom: 4rem;
}

.mt0 {
  margin-top: 0;
}

.mt1 {
  margin-top: 1rem;
}

.mt2 {
  margin-top: 2rem;
}

.mt3 {
  margin-top: 3rem;
}

.mt4 {
  margin-top: 4rem;
}

.pb0 {
  padding-bottom: 0;
}

.pb1 {
  padding-bottom: 1rem;
}

.pb2 {
  padding-bottom: 2rem;
}

.pb3 {
  padding-bottom: 3rem;
}

.pb4 {
  padding-bottom: 4rem;
}

.pt0 {
  padding-top: 0;
}

.pt1 {
  padding-top: 1rem;
}

.pt2 {
  padding-top: 2rem;
}

.pt3 {
  padding-top: 3rem;
}

.pt4 {
  padding-top: 4rem;
}
